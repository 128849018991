import * as React from 'react';
// material-ui
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, CardContent, Grid, InputAdornment, TablePagination, TextField, Tooltip } from '@mui/material';

// project imports
// import { Customer } from 'types/customer';
// import MainCard from 'components/cards/MainCard';

// assets
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { IconDownload } from '@tabler/icons';
import CustomSelectCenter from 'components/CustomSelectCenter';
import EmptyData from 'components/errors/empty-data';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_REQUEST, GET_ALL_REQUEST, Order, Roles, useStyles } from 'config';
import UIManager from 'core/UIManager';
import { debounce, upperFirst } from 'lodash';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { CentreApi, LeaveApi, RatioApi } from 'services/api';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { LeaveKecho } from 'types/kecho/leave-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import AddAbsenceModal from '../CalculationStaffRatio/List/Components/ClassTable/components/AbsenceModal';
import { DeleteModal, UploadFileModal } from './components';

interface Row extends LeaveKecho {
    nameStaff?: string;
    action?: string;
}

const AbsenceList = () => {
    const classes = useStyles();
    const [params] = useSearchParams();
    const idParam: any = params.get('idLeave');
    const centerId: any = params.get('centerId');
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectionCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectionCenterID : userCenterFirstID;
    const [leaveList, setLeaveList] = React.useState<LeaveKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);

    const cols: IColumnTable<Row>[] = [
        {
            field: 'nameStaff',
            headerName: 'Staff Name',
            isSort: true,
            width: '10%',
            render: (row) => <>{row?.staff?.nameStaff}</>
        },
        {
            field: 'status',
            headerName: 'Status',
            isSort: true,
            width: '10%',
            render: (row) => <>{upperFirst(row?.status)}</>
        },
        {
            field: 'dateApproved',
            headerName: 'Date Approved',
            isSort: true,
            width: '10%',
            render: (row) => <>{row?.dateApproved ? moment(row?.dateApproved).format('DD/MM/YYYY') : '---'}</>
        },
        {
            field: 'dateLeave',
            headerName: 'Date Leave',
            isSort: true,
            width: '10%',
            render: (row) => <>{row?.dateLeave ? moment(row?.dateLeave).format('DD/MM/YYYY') : '---'}</>
        },
        {
            field: 'typeLeave',
            headerName: 'Absence Type',
            isSort: true,
            width: '10%'
        },
        {
            field: 'remark',
            headerName: 'Remark',
            isSort: true,
            width: '10%'
        },
        {
            field: 'action',
            headerName: 'Action',
            isSort: false,
            width: '10%',
            render: (row) => (
                <>
                    {!row?.isInfoTech && (
                        <Tooltip placement="top" title="Remove">
                            <Button
                                variant="outlined"
                                style={{ color: '#ff0000', border: '1px solid #ff0000' }}
                                onClick={() => handleOpenDeleteModal(row)}
                            >
                                <DeleteOutlineIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }} /> Remove
                            </Button>
                        </Tooltip>
                    )}
                </>
            )
        }
    ];

    React.useEffect(() => {
        if (isShowCenterFilter()) handleGetCentreList();
        else setFilter({ ...filter, centreId: userCenterID || userSelectionCenterID || centerId });
    }, [centerId]);
    React.useEffect(() => {
        handleGetList();
    }, [filter]);

    const handleGetList = async () => {
        const payload = { ...filter, idRef: idParam, order: filter.order };

        const { dataList, meta } = await LeaveApi.listLeave(payload);
        if (dataList) {
            setLeaveList(dataList);
        }
        if (meta) setMetaData(meta);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event?.target.value;
        setFilter({ ...filter, query, page: 1 });
    };
    const handleSearch = debounce(onChange, 500);

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const order = filter.order === Order.DESC && filter.sortColumn === sortColumn ? Order.ASC : Order.DESC;
        setFilter({ ...filter, sortColumn, order });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleOpenModalAddAbsence = () => {
        UIManager.showModal({
            content: (onClose: any) => <AddAbsenceModal onClose={onClose} callback={handleGetList} centreId={filter.centreId} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };

    const handleUploadFileModal = () => {
        UIManager.showModal({
            content: (onClose: any) => <UploadFileModal onClose={onClose} onUpload={handleUploadFile} />,
            stylesModal: {
                minWidth: 600
            }
        });
    };

    const handleUploadFile = async (formData: FormData, isApprove = false) => {
        if (isApprove) {
            const { data } = await LeaveApi.importApproveLeaveExcel(formData);
            if (data) {
                RatioApi.checkLeave();
                UIManager.showAlert({
                    message: 'Import file successfully',
                    alert: {
                        color: 'success'
                    }
                });
                handleGetList();
                return true;
            }
            return false;
        }
        const { data } = await LeaveApi.importPendingLeaveExcel(formData);
        if (data) {
            UIManager.showAlert({
                message: 'Import file successfully',
                alert: {
                    color: 'success'
                }
            });
            handleGetList();
            return true;
        }
        return false;
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    const handleOpenDeleteModal = (leave: LeaveKecho) => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteModal onClose={onClose} callback={handleGetList} leave={leave} />
        });
    };
    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList?.length > 0) {
            setCentreData(dataList);
            if (isShowCenterFilter()) {
                if (centerId) {
                    setFilter({ ...filter, centreId: centerId });
                } else {
                    setFilter({ ...filter, centreId: dataList[0]?.id });
                }
            }
        }
    };
    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.Admin;
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Absence List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search by name"
                            size="small"
                            style={{ marginRight: 12 }}
                        />
                        {isShowCenterFilter() && (
                            <CustomSelectCenter
                                data={centreData}
                                value={centreData.length > 0 ? filter.centreId : ''}
                                name="centerId"
                                label="Center"
                                onChange={(e) => {
                                    setFilter({ ...filter, centreId: e.target.value, page: 1 });
                                }}
                                selectProps={{
                                    MenuProps: {
                                        classes: {
                                            paper: classes.selectMenu
                                        }
                                    }
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item sx={{ textAlign: 'right' }}>
                        <Grid container justifyContent="flex-end">
                            <Tooltip title="Import file absence" placement="top" style={{ marginRight: 8 }}>
                                <Button
                                    variant="outlined"
                                    style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginLeft: 10 }}
                                    onClick={handleUploadFileModal}
                                    startIcon={<IconDownload style={{ transform: 'rotate(180deg)' }} />}
                                >
                                    Import
                                </Button>
                            </Tooltip>

                            <Tooltip title="Add new absence" placement="top">
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                                    size="large"
                                    onClick={handleOpenModalAddAbsence}
                                    startIcon={<AddIcon />}
                                >
                                    Add
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>

            {/* table */}
            {leaveList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <Box sx={{ borderRadius: '6px' }}>
                        <TableCustom
                            hasSort
                            rows={leaveList}
                            columns={cols}
                            onRequestSort={handleRequestSort}
                            order={filter.order.toLowerCase()}
                            orderBy={filter.sortColumn}
                        />
                    </Box>
                    {/* table pagination */}

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default AbsenceList;
