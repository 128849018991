import * as React from 'react';

import { useNavigate } from 'react-router-dom';
// material-ui
import { Box, Button, CardContent, Grid, InputAdornment, Stack, TablePagination, TextField, Tooltip } from '@mui/material';

// project imports
// import Chip from 'components/extended/Chip';
// import MainCard from 'components/cards/MainCard';

// assets
import SearchIcon from '@mui/icons-material/Search';

import ViewIcon from 'assets/images/icons/page-actions/tabler-icon-eye.svg';
import EmptyData from 'components/errors/empty-data';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import { DEFAULT_REQUEST, Order } from 'config';
import { upperFirst } from 'lodash';
import centreApi from 'services/api/centreApi';
import { HeadCell } from 'types';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';

// table header options
const headCells: HeadCell[] = [
    {
        id: 'littleLiveId',
        numeric: false,
        label: 'Little Live ID',
        align: 'center'
    },
    {
        id: 'nameCenter',
        numeric: true,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'hoursFrom',
        numeric: true,
        label: 'Operating hours (From) ',
        align: 'left'
    },
    {
        id: 'hoursTo',
        numeric: true,
        label: 'Operating hours (To) ',
        align: 'left'
    },
    {
        id: 'ECDA_ID',
        numeric: false,
        label: 'ECDA ID',
        align: 'left'
    },
    {
        id: 'address',
        numeric: false,
        label: 'Address',
        align: 'left'
    }
];

interface Row extends CentreKecho {
    action?: string;
}

const CentreList = () => {
    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);
    const [centre, setCentre] = React.useState<CentreKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const navigate = useNavigate();

    const col: IColumnTable<Row>[] = [
        {
            field: 'littleLiveId',
            headerName: 'Little Live ID',
            isSort: true
        },
        {
            field: 'nameCenter',
            headerName: 'Name',
            isSort: true,
            width: 400,
            render: (row) => <>{row?.nameCenter}</>
        },
        {
            field: 'hoursFrom',
            headerName: 'Operating hours (From)',
            isSort: true
        },
        {
            field: 'hoursTo',
            headerName: 'Operating hours (To)',
            isSort: true
        },
        {
            field: 'ECDA_ID',
            headerName: 'ECDA ID',
            isSort: true
        },
        {
            field: 'address',
            headerName: 'Address',
            isSort: true,
            width: 300,
            render: (row) => <>{upperFirst(row?.address)}</>
        },
        {
            field: 'action',
            headerName: 'Action',
            isSort: false,
            render: (row) => (
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Tooltip title="View detail" placement="top">
                        <Button
                            variant="outlined"
                            style={{ color: '#3F51B5', border: '1px solid #3F51B5' }}
                            onClick={() => {
                                navigate(`/centre/centre-details/${row?.id}`);
                            }}
                            // href={`/centre/centre-details/${row?.id}`}
                        >
                            <img src={ViewIcon} alt="view" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> View
                        </Button>
                    </Tooltip>
                </Stack>
            )
        }
    ];

    React.useEffect(() => {
        const searchInputTimer = setTimeout(() => {
            handleGetDataCentre();
        }, 800);
        return () => clearTimeout(searchInputTimer);
    }, [filter]);

    const handleGetDataCentre = async () => {
        const { dataList, meta } = await centreApi.listCentre(filter);
        setCentre(dataList || []);
        setMetaData(meta || {});
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const searchString = event?.target.value;
        setFilter((prev) => ({ ...prev, query: searchString || '' }));
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const newOrder = filter.order === Order.DESC && filter.sortColumn === property ? Order.ASC : Order.DESC;
        setFilter((prev) => ({ ...prev, sortColumn: property, order: newOrder }));
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Centre List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0, paddingBottom: '15px' }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search by name"
                            value={filter.query}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {centre.length ? (
                <Box sx={{ padding: '0px 24px' }}>
                    <Box sx={{ borderRadius: '6px' }}>
                        <TableCustom
                            hasSort
                            rows={centre}
                            columns={col}
                            onRequestSort={handleRequestSort}
                            order={filter.order.toLowerCase()}
                            orderBy={filter.sortColumn}
                        />

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={metaData?.itemCount || 0}
                            rowsPerPage={filter.take}
                            page={filter.page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default CentreList;
